import React from 'react';
import './App.css';
import lore from './lore.png';

function App() {
  return (
    <div className="App">
      <img src={lore} alt="Your gif" />
    </div>
  );
}


export default App;
